import request from './request.js'

export const login = (params) => request.post('/eams/outbound/sys-users/login', params)
export const getUserInfo = () => request.get('/eams/outbound/sys-users/getUserInfo')
export const logout = () => request.get('/eams/outbound/sys-users/logout')

export const getCaptcha = () => request.get('/eams/outbound/captcha/getCaptcha', { responseType: 'arraybuffer' })
export const checkCaptcha = ({ captcha }) => request.get(`/eams/outbound/captcha/checkCaptcha?captcha=${captcha}`)
export const applyForm = (param) => request.post('/eams/outbound/consult/save', param)

export const verificationCodeSend = (param) => request.post('/eams/outbound/sms/verificationCodeSend', param)

export const loginCode = (phone, verificationCode) => request.post('/eams/outbound/sys-users/phoneLogin', { phone, verificationCode })

// 产品中心接口
// 科技尽调报告 - 申请注册会员权益
export const getApplyRegisterRights = () => request.get('/eams/outbound/techReport/applyRegisterRights')

// 科技尽调报告 - 查询会员权益
export const getRightsInfo = () => request.get('/eams/outbound/techReport/getRightsInfo')

// 用户科技尽调报告查看权限
export const getTecReportLimit = () => request.get('/eams/outbound/techReport/viewPageLimit')

//专利报告 
//专利估值报告-查询会员权益
export const getPatentRightsInfo = () => request.get('/eams/outbound/patentReport/getRightsInfo')

//专利估值报告 - 访问前鉴权
export const getPatentReportLimit = () => request.get('/eams/outbound/patentReport/viewPageLimit')

//专利估值报告 - 申请注册会员权益
export const getPatentApplyRegisterRights = () => request.get('/eams/outbound/patentReport/applyRegisterRights')

//专利报告（新）
//专利估值报告（新）-查询会员权益
export const getNewPatentRightsInfo = () => request.get('/eams/outbound/patentEvaluationReport/getRightsInfo')

//专利估值报告（新） - 申请注册会员权益
export const getNewPatentApplyRegisterRights = () => request.get('/eams/outbound/patentEvaluationReport/applyRegisterRights')

//专利估值报告（新） - 访问前鉴权
export const getNewPatentReportLimit = () => request.get('/eams/outbound/patentEvaluationReport/viewPageLimit')


//获取个人权益
export const getPersonalRights = (params) => request.get('/eams/outbound/user-rights/getPersonalRights', { params })

//获取子账号列表
export const getChildrenList = (params) => request.post('/eams/outbound/account-center/getChildrenList', params)

//更新子账号
export const updateChildrenAccount = (params) => request.post('/eams/outbound/account-center/updateChildrenAccount', params)

// 名单列表
export const getRosterList = (params) => request.post('/eams/outbound/user-rosters/getRosterList', params)

// 名单列表V2
export const getRosterPageV2 = (params) => request.post('/eams/outbound/user-rosters/getRosterPageV2', params)

//更新名单名称
export const updateRosterName = (params) => request.post('/eams/outbound/user-rosters/updateRosterName', params)

//账户名单管理-导出用户名单
export const buildExportTask = (params) => request.get('/eams/outbound/user-rosters/buildExportTask', { params })

//账户名单管理-导出状态查询
export const getExportTaskStatus = (params) => request.get('/eams/outbound/user-rosters/getExportTaskStatus', { params })

//账户名单管理-获取导出名单下载路径
export const getDownloadUrl = (params) => request.get('/eams/outbound/user-rosters/getDownloadUrl', { params })

// 批量删除名单
export const deleteRosterList = (params) => request.get('/eams/outbound/user-rosters/deleteRosterList', params)

//名单下的企业列表
export const getRosterEnterpriseList = (params) => request.post('/eams/outbound/user-rosters/getRosterEnterpriseList', params)

//名单下的企业列表
export const getRosterEnterpriseListV2 = (params) => request.post('/eams/outbound/user-rosters/getRosterEnterpriseListV2', params)

//分发子账号列表
export const getRosterAccountChildrenList = (params) => request.get('/eams/outbound/user-rosters/getRosterAccountChildrenList', { params })

//分发企业列表到子账号
export const dispatchEnterpriseToAccountChildren = (params) => request.post('/eams/outbound/user-rosters/dispatchEnterpriseToAccountChildren', params)

//分发企业列表到子账号
export const dispatchEnterpriseToAccountChildrenV2 = (params) => request.post('/eams/outbound/user-rosters/dispatchEnterpriseToAccountChildrenV2', params)

//获取区域数据
export const getAllCityTreeNodeList = (params) => request.get('/eams/outbound/universal/city/getAllCityTreeNodeList', { params })

//账户信息
export const getPersonalInfo = (params) => request.get('/eams/outbound/user-center/getPersonalInfo', { params })

//修改昵称
export const updateNickName = (params) => request.get('/eams/outbound/user-center/updateNickName', { params })


//修改密码
export const updatePassword = (params) => request.post('/eams/outbound/user-center/updatePassword', params)

//查询套餐
export const getInvokerPackageUsedInfo = (params) => request.get('/eams/outbound/openApi/getInvokerPackageUsedInfo', { params })

//校验token是否过期
export const ifValidToken = (params) => request.get('/eams/outbound/sys-users/ifValidToken', { params })

//瞰见 - 访问前鉴权
export const getAerialviewLimit = (params) => request.get('/aerialview/outbound/rights/viewPageLimit', { params })

//企业硬科技属性评价报告列表
export const pageEnterpriseHardIndicatorReportList = (params) => request.get('/eams/outbound/hardIndicatorReport/pageEnterpriseHardIndicatorReportList', { params })

//硬科技报告-查询导出任务状态
export const getHardExportTaskStatus = (params) => request.get('/eams/outbound/hardIndicatorReport/getExportTaskStatus', { params })

//硬科技报告-获取报告下载路径
export const getHardDownloadUrl = (params) => request.get('/eams/outbound/hardIndicatorReport/getDownloadUrl', { params })

//硬科技报告-检查用户权益
export const checkRights = (params) => request.get('/eams/outbound/hardIndicatorReport/checkRights', { params })


// 风险监控
export const riskMonitor = {
    //查询用户是否进行站内信消息提醒
    checkMonitorNoticeUser: () => request.get('/eams/outbound/monitor/checkMonitorNoticeUser'),
    //删除站内信提醒
    deleteMonitorNoticeUser: () => request.get('/eams/outbound/monitor/deleteMonitorNoticeUser'),
    //获取监控企业消息（分页）
    getMonitorEnterpriseSearchMessageInfoPage: (params) => request.post('/eams/outbound/monitor/getMonitorEnterpriseSearchMessageInfoPage', params),
    //根据企业名称模糊检索（分页）检索监控企业
    searchMonitorPateEnterpriseUser: (params) => request.post('/eams/outbound/monitor/searchMonitorPateEnterpriseUser', params),
    //获取单个企业消息详情
    getEnterpriseSearchMessagePage: (params) => request.get('/eams/outbound/monitor/getEnterpriseSearchMessagePage', { params }),
    //用户删除监控企业
    deleteMonitorPateEnterpriseUser: (params) => request.post('/eams/outbound/monitor/deleteMonitorPateEnterpriseUser', params),
    //获取用户当前已有最高权益的天数
    getHighestRightsRestDays: (params) => request.get('/eams/outbound/monitor/getHighestRightsRestDays', { params }),
}
